export default function initSlideToggle() {
  function animateInMaxHeight(element) {
    function removeMaxHeight() {
      this.style.maxHeight = 'none';
      this.removeEventListener('transitionend', transitionEndCallback);
      delete element._transitionEnd;
    }

    let transitionEndCallback = removeMaxHeight.bind(element);
    element._transitionEnd = transitionEndCallback;

    element.style.maxHeight = `${element.scrollHeight}px`;
    element.addEventListener('transitionend', transitionEndCallback);
  }

  function animateOutMaxHeight(element) {
    element.style.maxHeight = `${element.scrollHeight}px`;
    element.offsetHeight;

    if (element._transitionEnd) {
      element.removeEventListener('transitionend', element._transitionEnd);
    }

    setTimeout(function () {
      element.style.maxHeight = '0px';
    }, 1);
  }

  function animateToggle() {
    const toggleTriggers = document.querySelectorAll(
      `[data-toggle=animatetoggle]`,
    );
    toggleTriggers.forEach((trigger) => {
      let toggle = document.querySelector(
        `[aria-labelledby=${trigger.getAttribute('id')}]`,
      );

      let videos = toggle.querySelectorAll('video');

      if (toggle.classList.contains('hidden')) {
        toggle.style.maxHeight = `0px`;
        toggle.classList.remove('hidden');
      }
      toggle.classList.add('overflow-hidden');
      toggle.style.transition = `max-height 400ms`;

      function openItem() {
        trigger.setAttribute('aria-expanded', 'true');
        animateInMaxHeight(toggle);
      }

      function closeItem() {
        trigger.setAttribute('aria-expanded', 'false');
        animateOutMaxHeight(toggle);
        videos.forEach((video) => {
          video.pause();
        });
      }

      // Main button click event
      trigger.addEventListener(
        'click',
        (e) => {
          e.preventDefault();

          if (trigger.getAttribute('aria-expanded') === 'false') {
            openItem();
          } else {
            closeItem();
          }
        },
        false,
      );

      // Close button inside the div
      const closeButton = toggle.querySelector('.close-slidetoggle');
      if (closeButton) {
        closeButton.addEventListener('click', (e) => {
          e.preventDefault();
          closeItem(); // Use the same close function
        });
      }
    });
  }

  animateToggle();
}
