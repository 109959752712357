import tippy from 'tippy.js';

export default function tooltip() {
    tippy('[data-tippy-template]', {
        content(reference) {
            const templateName = reference.getAttribute('data-tippy-template');
            const template = document.querySelector(`[data-tippy-template-target="${templateName}"]`);
            return template.innerHTML;
        },
        maxWidth: 'none',
        allowHTML: true,
        trigger: 'click',
        interactive: true,
        appendTo: () => document.body,
    });
}