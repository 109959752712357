export default function initToggleListActive() {
  const setActive = (el) => {
    [...el.parentElement.children].forEach((sib) =>
      sib.classList.remove('is-active'),
    );
    el.classList.add('is-active');
  };
  let items = [
    ...document.body.querySelectorAll(
      '[data-list-toggles] > [data-active-toggle]',
    ),
  ];
  items.forEach((el) => el.addEventListener('click', (e) => setActive(el)));
}
