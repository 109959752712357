export default function copyUrlBtn() {
  const isPresent = document.querySelector('.copy-url-btn');
  if (!isPresent) return;

  const copyUrlBtn = document.querySelectorAll('.copy-url-btn');

  copyUrlBtn.forEach((shareBtn) => {
    shareBtn.addEventListener('click', function () {
      
      // Create a temporary input element
      const tempInput = document.createElement('input');
      // Set its value to the current URL
      tempInput.value = window.location.href;
      // Append it to the body
      document.body.appendChild(tempInput);
      // Select the input's value
      tempInput.select();
      // Copy the selected value to the clipboard
      document.execCommand('copy');
      // Remove the temporary input element
      document.body.removeChild(tempInput);
      // Change the button text to "url copied"
  
      const initialText = this.querySelector('.copy-url-btn-initial-text');
      const afterText = this.querySelector('.copy-url-btn-after-text');
  
      if (!initialText || !afterText) return;
  
      initialText.classList.add('hidden');
      afterText.classList.remove('hidden');
    });
  });
}
