import './global.css';
import openMenu from './scripts/openMenu';
import toggleVideo from './scripts/toggleVideo';
import {
  initPlatformOverview,
  initStoriesItems,
} from './scripts/gsapAnimations';
import initToggles from './scripts/generalToggle';
import initDropdown from './scripts/dropdown';
// import initDropdownIconChange from './scripts/mainMenuIcon';
import {
  initPeopleSlider,
  initStoriesSlider,
  initVideoSlider,
  initPartnersSlider,
  initInterestsSlider,
} from './scripts/sliders';
import initTriggerOpen from './scripts/toggleopen';
import initTabs from './scripts/tabs';
import copyUrlBtn from './scripts/copyURLButton';
import initSlideToggle from './scripts/slideToggle';
import initToggleListActive from './scripts/generalListActiveToggle';
import tooltip from './scripts/tooltip';
import selectEnhancer from './scripts/selectEnhancer';
import videoModal from './scripts/videoModal';

openMenu();
toggleVideo();
initToggles();
initDropdown();
initPeopleSlider();
initStoriesSlider();
initPartnersSlider();
initInterestsSlider();
initVideoSlider();
initTabs();
copyUrlBtn();
initSlideToggle();
initToggleListActive();
tooltip();
selectEnhancer();
videoModal();

// initDropdownIconChange();
initTriggerOpen();

function checkScreenWidth() {
  const screenWidth = window.innerWidth;

  if (screenWidth > 768) {
    initPlatformOverview();
  }

  if (screenWidth > 900) {
    initStoriesItems();
  }
}

checkScreenWidth();

window.addEventListener('resize', function () {
  checkScreenWidth();
});

document.querySelectorAll('[data-modal-close]')?.forEach((element) => {
  const dialog = element.closest('dialog');
  element.addEventListener('click', () => {
    dialog.close();
  });
});
