import jQuery from "jquery";
import select2 from "select2";
export default function selectEnhancer() {
    setTimeout( function() {
        jQuery(function($) {
            $('select').each(function () {
                const placeholder = $(this).data('placeholder');
                const hasSearch = $(this).data('has-search');
                const isSkillAssessmentOption = $(this).parents('.skill-assessment').length;
                let cssClasses = isSkillAssessmentOption ? 'select2-skill-assessment' : '';
                    cssClasses += hasSearch ? ' select2-has-search' : '';
                    
                $(this).select2({
                    dropdownAutoWidth: true,
                    minimumResultsForSearch: hasSearch ? 0 : Infinity,
                    placeholder: {
                        id: '-1',
                        text: placeholder
                    },
                    dropdownCssClass: cssClasses,
                    width: isSkillAssessmentOption ? 'style' : 'resolve',
                });
            });
        });
    }, 10);
}
