function modal() {
    const modals = document.querySelectorAll('dialog');
    const curriculumOpeners = document.querySelectorAll('[data-modal]');

    if (!modals) {
        return;
    }

    curriculumOpeners.forEach(opener => {
        const targetContentId = opener.getAttribute('href');
        const targetModal = document.querySelector(opener.getAttribute('data-modal'));
        
        const targetContent = targetModal.querySelector(targetContentId); 
        const modalCloseButton = targetModal.querySelectorAll('.modal__close');
        opener.addEventListener('click', function (e) {
            targetModal.showModal();
            targetContent.classList.add('is-visible');
        });

        modalCloseButton.forEach(button => {
            button.addEventListener('click', function (e) {
                e.target.closest('dialog').close();
            });
        });
    });

    modals.forEach(theModal => {
        const modalCloseAllButtons = theModal.querySelectorAll('.modal__close-all');

        theModal.addEventListener('close', function () {
            theModal.querySelectorAll('.is-visible').forEach(element => {
                element.classList.remove('is-visible');
            });
        });

        // Close modal when clicking on backdrop
        theModal.addEventListener('click', function(event) {
            const rect = theModal.getBoundingClientRect();
            const isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
            rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
            if (!isInDialog) {
                theModal.close();
            }
        });

        modalCloseAllButtons.forEach(button => {
            button.addEventListener('click', function (e) {
                theModal.close();
            });
        });
    });
}

export { modal };