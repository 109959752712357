export default function videoModal() {
    const dialogButtons = document.querySelectorAll('[data-video-dialog-target]');

    if(dialogButtons.length == 0) {
        return;
    }

    dialogButtons.forEach((button) => {
        const dialog = document.querySelector(`[data-video-dialog-element="${button.dataset.videoDialogTarget}"]`);

        if(!dialog) {
            return;
        }

        button.addEventListener('click', () => {
            dialog.showModal();
        });
    });
}