 function toggleVideo() {
  const isPresent = document.querySelector('.play-pause-btn');
  if (!isPresent) {
    return;
  }

  // const video = document.querySelector('.main-hero-video');
  // const videoWrapper = document.querySelector('.front-video-wrap');
  // const btn = document.querySelector('.play-pause-btn');

  // btn.addEventListener('click', () => {
  //   if (video.paused) {
  //     video.play();
  //     btn.classList.add('playing');
  //     videoWrapper.classList.add('playing');
  //   } else {
  //     video.pause();
  //     btn.classList.remove('playing');
  //     videoWrapper.classList.remove('playing');
  //   }
  // });

  const videoWrappers = document.querySelectorAll('.video-wrap');

  videoWrappers.forEach((wrapper) => {
    const video = wrapper.querySelector('.video-wrap-video');
    const btns = wrapper.querySelectorAll('.play-pause-btn');

    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        togglePlay();
      });
    });

    video.addEventListener('play', () => {
        btns.forEach((b) => {
          b.classList.add('playing');
        });
        wrapper.classList.add('playing');
    });
    video.addEventListener('pause', () => {
        btns.forEach((b) => {
          b.classList.remove('playing');
        });
        wrapper.classList.remove('playing');
    });

    function togglePlay() {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  });
}

export { toggleVideo };

function toggleSound() {
  const isPresent = document.querySelector('.mute-unmute-btn');
  if (!isPresent) {
    return;
  }

  const videoWrappers = document.querySelectorAll('.video-wrap');

  videoWrappers.forEach((wrapper) => {
    const video = wrapper.querySelector('.video-wrap-video');
    const btns = wrapper.querySelectorAll('.mute-unmute-btn');

    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        if (video.muted) {
          video.muted = false;

          btns.forEach((b) => {
            b.classList.remove('muted');
          });

          wrapper.classList.remove('muted');
        } else {
          video.muted = true;

          btns.forEach((b) => {
            b.classList.add('muted');
          });

          wrapper.classList.add('muted');
        }
      });
    });
  });
}

export { toggleSound };

function toggleFullScreen() {
  const isPresent = document.querySelector('.full-screen-btn');
  if (!isPresent) {
    return;
  }

  const videoWrappers = document.querySelectorAll('.video-wrap');

  videoWrappers.forEach((wrapper) => {
    const video = wrapper.querySelector('.video-wrap-video');
    const btns = wrapper.querySelectorAll('.full-screen-btn');

    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        video.requestFullscreen();
      });
    });
  });
}

export { toggleFullScreen };